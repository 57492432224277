import { JsonPipe } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  ResolveFn,
  Router,
  RouterLink,
  RouterLinkActive,
  RouterStateSnapshot,
} from '@angular/router';
import { GoogleMapComponent } from 'src/app/components/_elements/google-map/google-map.component';
import { ViewContentComponent } from 'src/app/components/_layout/view-content/view-content.component';
import { ViewHeaderComponent } from 'src/app/components/_layout/view-header/view-header.component';
import { ButtonComponent } from 'src/app/components/form/button/button.component';
import { FormFieldComponent } from 'src/app/components/form/form-field/form-field.component';
import { GmapInputComponent } from 'src/app/components/form/input/gmap-input.component';
import { InputComponent } from 'src/app/components/form/input/input.component';
import { SelectComponent } from 'src/app/components/form/select/select.component';
import { asObject } from 'src/app/enum';
import { Status } from 'src/app/enum/status';
import { ActionHelper } from 'src/app/helpers/action';
import { LxmDialog } from 'src/app/helpers/dialogs';
import { LxmMessage } from 'src/app/helpers/messages';
import { LocationsService } from 'src/app/services/locations.service';
import { OverlayViewService } from 'src/app/components/_layout/overlay-view/overlay-view.service';

export const locationResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const { id } = route.params || {};
  return inject(LocationsService).getLocationById(id);
};

export const locationFormDataResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  return inject(LocationsService).getLocationsFormdata();
};

@Component({
  selector: 'location-view',
  templateUrl: './location.view.html',
  styleUrls: ['./location.view.scss'],
  standalone: true,
  imports: [
    ButtonComponent,
    SelectComponent,
    InputComponent,
    FormFieldComponent,
    ReactiveFormsModule,
    JsonPipe,
    ViewHeaderComponent,
    ViewContentComponent,
    GoogleMapComponent,
    GmapInputComponent,
  ],
  providers: [LxmMessage, ActionHelper],
  host: {
    id: 'view',
  },
})
export class LocationViewComponent {
  public formGroup: FormGroup;

  public edit = true;

  public statusOptions = [Status.Active, Status.Disabled].map((x) =>
    asObject(Status, Number(x)),
  );

  // public statusOptions = [
  //   {
  //     name: 'Aktiivne',
  //   },
  // ];

  public formatOptions = [
    {
      name: 'Mugavuskauplus',
    },
    {
      name: 'Ebamugavuskauplus',
    },
  ];

  public formData: any;

  public actionLoading = {} as any;

  public locationId: string;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _locationsService: LocationsService,
    private _action: ActionHelper,
    private _dialog: LxmDialog,
    private _message: LxmMessage,
    private OVService: OverlayViewService,
  ) {
    this.locationId = _route.snapshot.params['id'];

    if (this.locationId) {
      const { data } = _route.snapshot.data || {};
      const { location, formData } = data || {};

      var {
        address,
        addressObject,
        status,
        chainBrand,
        name,
        email,
        gln,
        format,
        areaInSqm,
      } = location || {};

      this.formData = formData;
    } else {
      this.formData = _route.snapshot.data['formData'];
    }

    this.formGroup = new FormGroup({
      status: new FormControl(status ?? Status.Active),
      chainBrandId: new FormControl(chainBrand?.id),
      name: new FormControl(name),
      gln: new FormControl(gln),
      formatId: new FormControl(format?.id),
      // areaInSqm: new FormControl(areaInSqm),
      email: new FormControl(email),
      address: new FormControl(address),
      gmap_address: new FormControl(addressObject),
    });

    console.log(chainBrand, this.formGroup.value);
  }

  public save() {
    console.log(this.formGroup.value);
    this._locationsService
      .saveLocation(this.formGroup.value, this.locationId)
      .result(
        this.formGroup,
        (res) => {
          if (!this.locationId) {
            this._router.navigate(['/locations', res], { replaceUrl: true });
          }
        },
        (err) => {},
        'save',
        this.actionLoading,
        this._action.createMessage('views.location.save_location_success'),
      );
  }

  public delete() {
    this.OVService.confirm({
      id: 'confirm-delete',
      headerLabel: 'views.location.confirm_delete_location_title',
      confirmText: 'views.location.confirm_delete_location_text',
      onConfirm: () => {
        // alert('DELETE');
        // return;
        this._locationsService.deleteLocation(this.locationId).result(
          null,
          (x) => {
            this._router.navigate(['/locations'], { replaceUrl: true });

            this._message.ok({
              message: 'messages.location_deleted',
            });
          },
          (err) => {
            this._message.error({
              messages: ['messages.default_error', err.validationSummary],
            });
          },
          'delete',
          this.actionLoading,
        );
      },
    });
  }
}
