<ng-container [formGroup]="formGroup">
  <input
    class="inline-flex max-w-full flex-1 overflow-ellipsis outline-none"
    #input
    [id]="for"
    [type]="type"
    [formControlName]="for"
    [autocomplete]="autocomplete"
    [placeholder]="placeholder ? (placeholder | translate) : ' '"
    [attr.disabled]="disabled"
    [pattern]="pattern"
    [mask]="mask ? mask.mask : null"
    [prefix]="mask ? mask.prefix : ''"
    [suffix]="mask ? mask.suffix : suffix"
    [allowNegativeNumbers]="mask ? mask.allowNegativeNumbers : null"
    [decimalMarker]="mask ? mask.decimalMarker : null"
    (blur)="blur.emit($event)"
  />

  @if (!readonly) {
    @if (clearable && hasValue) {
      <div
        class="icons-container absolute right-0 top-0 flex h-full cursor-pointer items-center gap-2 bg-inherit px-5x"
        (click)="clearValue()"
        matRipple
      >
        <div class="icon-container flex size-7x items-center justify-center">
          <svg-icon src="monochromeX" class="text-grey-100"></svg-icon>
        </div>
        <ng-content select="[right]"></ng-content>
      </div>
    }
  }
</ng-container>
