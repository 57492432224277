<view-header
  [label]="formGroup.value.name || 'views.locations.new_location'"
  [backRoute]="{ label: 'menu.navigator.locations', route: '/locations'}"
>
  @if(locationId) {
  <div class="flex w-full justify-end">
    <button
      appButton
      label="action.delete"
      icon="trash"
      color="transparent"
      (click)="delete()"
    ></button>
  </div>
  }
</view-header>

<view-content>
  <form [formGroup]="formGroup">
    @if(formGroup.value.gmap_address) {
    <div class="mb-5x min-h-60 min-w-full lg:min-h-80">
      <google-map for="gmap_address" [readonly]="!edit"></google-map>
    </div>
    }

    <form-field label="common.label.status" [required]="true" for="status">
      <app-select
        for="status"
        [options]="statusOptions"
        [clearable]="false"
        value="id"
      ></app-select>
    </form-field>

    <form-field label="common.label.chain_brand" for="chainBrandId">
      <!-- <app-input for="chainBrand"></app-input> -->
      <app-select
        for="chainBrandId"
        [options]="formData?.chainBrands || []"
        [clearable]="false"
        labelField="value"
        value="id"
      ></app-select>
    </form-field>

    <form-field label="common.label.location_name" [required]="true" for="name">
      <app-input for="name"></app-input>
    </form-field>

    <form-field label="common.label.address" [required]="true" for="address">
      <app-gmap-input
        googlePlaceFor="gmap_address"
        for="address"
      ></app-gmap-input>
    </form-field>

    <form-field label="common.label.gln" for="gln">
      <app-input for="gln"></app-input>
    </form-field>

    <form-field label="common.label.format" [required]="true" for="formatId">
      <app-select
        for="formatId"
        [options]="formData?.storeFormats || []"
        labelField="value"
        value="id"
      ></app-select>
    </form-field>

    <!-- <form-field label="common.label.sales_area" for="areaInSqm">
      <app-input for="areaInSqm"></app-input>
    </form-field> -->

    <form-field label="common.label.email" for="email">
      <app-input for="email"></app-input>
    </form-field>

    <button
      class="mt-7 min-w-full"
      appButton
      size="medium"
      color="primary"
      label="action.save"
      icon="monochromeSave"
      [loading]="actionLoading.save"
      (click)="save()"
    ></button>
  </form>
</view-content>
