import {
  AfterViewInit,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Optional,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';
import { InputComponent } from './input.component';
import {
  CommonModule,
  CurrencyPipe,
  NgClass,
  NgSwitch,
  NgSwitchCase,
  NgSwitchDefault,
} from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { provideNgxMask, NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { PriceValuePipe } from 'src/app/pipes/price-value.pipe';
import { ValidationErrorsComponent } from '../validation-errors/validation-errors.component';
import { API_KEYS } from 'src/app/config/api-keys';
import { SvgIconComponent } from 'angular-svg-icon';
import { NumericValuePipe } from 'src/app/pipes/numeric-value.pipe';

@Component({
  selector: 'app-gmap-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  standalone: true,
  providers: [NumericValuePipe, PriceValuePipe, CurrencyPipe, provideNgxMask()],
  imports: [
    ValidationErrorsComponent,
    ReactiveFormsModule,
    TranslateModule,
    NgClass,
    NgSwitch,
    NgSwitchCase,
    NgSwitchDefault,
    NgxMaskDirective,
    NgxMaskPipe,
    SvgIconComponent,
    CommonModule,
  ],
})
export class GmapInputComponent
  extends InputComponent
  implements OnChanges, AfterViewInit
{
  @Input() public googlePlaceFor: string;
  @Input() public googlePlaceTypes = [];

  @Input() public autocompleteOptions: google.maps.places.AutocompleteOptions =
    {
      fields: ['formatted_address', 'geometry', 'name'],
      strictBounds: false,
      types: this.googlePlaceTypes,
    };

  public loader = new Loader({
    apiKey: API_KEYS.GOOGLE_MAPS,
    version: 'weekly',
    libraries: ['places'],
  });

  public override get id() {
    return this.inputId || this.for || this.googlePlaceFor;
  }

  public ngOnInit(): void {
    if (!this.autocomplete && this.for) {
      this.autocomplete = this.for;
    }
  }

  ngAfterViewInit() {
    if (this.googlePlaceFor) {
      this.loadGoogleAutocomplete();
    }
  }

  public loadGoogleAutocomplete() {
    if (
      'google' in window &&
      typeof google === 'object' &&
      typeof google.maps === 'object'
    ) {
      this.initGoogleAutocomplete();
    } else {
      this.loader.load().then(() => {
        this.initGoogleAutocomplete();
      });
    }
  }

  public initGoogleAutocomplete() {
    const input = document.getElementById(this.id) as HTMLInputElement;
    const autocomplete = new google.maps.places.Autocomplete(
      input,
      this.autocompleteOptions,
    );
    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      if (this.googlePlaceFor) {
        this.formGroup.get(this.for).setValue(place.formatted_address);
        this.formGroup.get(this.googlePlaceFor).setValue(place);
      }
    });
  }
}
